@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.summary-wrapper {
  background-color: $secondary;
  border-radius: 2px;
  border: 1px solid $primary;
  text-align: left;
  .title{
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 7px;
    color: $primary;
    padding: 20px 16px 0;
  }
  table {
    width: 100%;
    font-size: 12px;
    border-bottom: 0.5px solid $primary;
    padding: 0 16px 30px;
    th{
      font-weight: 700;
      color: $primary;
    }
    td {
      font-weight: 300;
    }
  }
  .total {
    display: flex;
    margin-top: 17px;
    font-size: 18px;
    font-weight: 700;
    color: $primary;
    justify-content: space-between;
    padding: 0 16px 16px;
  }
}