@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.login-wrapper {
  display: flex;
  .Login {
    flex: 1 0 100%;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 108px 33px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    background-image: url('../../../static/img/symbol_background.png');
    color: $primary;
    height: 100vh;
    .content-wrap {
      width: 100%;
      a {
        border-bottom: 1px solid $secondary-dark;
        color: $secondary-dark;
        font-weight: 500;
      }

      .logo {
        height: 70px;
        margin-bottom: 34px;
      }
      .form-wrapper{
        text-align: left;
        .title {
          font-size: 18px;
          font-weight: 500;
          font-family: inherit;
        }
        .register-suggestion{
          display: flex;
          justify-content: space-between;
          font-weight: 500;
          font-size: 16px;
        }
        .form {
          margin-top: 10px;
          .input-wrapper {
            .info {
              margin: 10px 0 0;
              label {
                font-size: 12px;
                font-weight: 500;
              }
            }
            input{
              width: 100%;
              height: 46px;
              border: 1px solid $primary;
              border-radius: 0;
              padding-left: 13px;
              font-weight: 300;
            }
          }
          button{
            width: 100%;
            text-transform: uppercase;
            font-size: 15px;
            border-radius: 6px;
            padding: 12px 0;
            margin-top: 14px;
          }
          .bottom-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 30px;
            font-size: 12px;
            .checkbox-wrapper {
              margin: 0;

              label {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }

  .background {
    display: none;
    flex: 1 0 50%;
    height: 100vh;
    width: 50%;
    background-image: url('../../../static/img/login_right_bg.png');
    background-position: center;
    background-size: cover;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .icon, .logo {
      width: 35%;
      display: block;
    }
  }
}

@media (min-width: $mobileBreakpoint) {
  .login-wrapper {
    .Login {
      flex: 1 0 50%;
      .content-wrap {
        width: 300px;
      }
    }
    .background {
      display: flex;
    }
  }
}
