@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.buy-bg {
  background-image: url('../../../static/img/symbol_background.png');
  padding: 50px 0;
  min-height: 100vh;
  background-repeat: repeat;
  background-size: 70%;
}
.SignUp {

  .ui.container.grid {
    .column.mobile.sixteen {
      padding: 0;
    }
  }

  color: $primary;
  font-family: 'Source Sans Pro', sans-serif;
  background-color: #fff;
  text-align: center;
  padding-top: 50px;
  min-height: 89vh;
  border-top: 1px solid $primary;
  border-bottom: 1px solid $primary;
  h1{
    font-weight: 900;
    font-size: 30px;
    font-family: inherit;
    margin: 0 34px 33px;
    color: #3B186E;
  }
  .info-wrapper {
    padding: 0 34px;
    text-align: center;
    .desc {
      text-align: left;
      padding: 0 15px;
      font-weight: 300;
      font-size: 12px;
      h2{
        font-weight: 900;
        font-family: inherit;
        font-size: 18px;
        color: #3B186E;
      }
    }
    .summary-wrapper {
      margin: 35px 0;
    }
    .logo {
      padding: 0 50px;
      margin-bottom: 40px;
      img {
        width: 100%;
        max-width: 180px;
      }
    }
  }
  .form {
    background-color: rgba(208,232,218,0.4);
    padding: 26px;
    text-align: left;
    margin: 0 -1em 30px;
    .PaymentForm {
      margin-top: 30px;
    }
    button{
      margin-top: 25px;
      width: 100%;
    }
  }
}
.StripeElement {
  width: 100%;
  border: 1px solid $primary;
  border-radius: 2px;
  background-color: #FFFFFF;
  padding: 13.1px 10px;
}

@media (min-width: $mobileBreakpoint) {
  .SignUp {
    .ui.grid{
      margin: 0;
    }

    .form-wrapper{
      .row {
        display: flex;
        justify-content: space-between;
        .input-wrapper {
          width: 47%;
        }
      }
    }
  }
}