@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.ResetPasswordLayout {
  text-align: center;
  background-image: url("../../../static/img/symbol_background.png");

  @media (min-width: $mobileBreakpoint) {
      width: 933px;
      margin-left: auto;
      margin-right: auto;
  }

  .logo {
    height: 42px;
    margin: 20px 0;
  }
  .content {
    padding: 30px 30px 50px 30px;
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;
    text-align: left;
    background-color: #fff;
    h1 {
      font-size: 22px;
      font-weight: 500;
      font-family: 'Source Sans Pro', sans-serif;
    }
    .form {
      font-size: 12px;
      .input-wrapper {
        label{
          font-weight: 800;
          margin-bottom: 8px;
        }
        input  {
          border: 1px solid $primary;
          border-radius: 2px;
          height: 45px;
          margin-bottom: 15px;
        }
      }
    }
  }
}