@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.AccountEdit {
  .form-wrapper {
    margin-top: 50px;
    border: 0.5px solid $primary;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 0 20px 30px 20px;
    .initials{
      background-color: $primary;
      height: 87px;
      width: 87px;
      margin: -43px auto 0 auto;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 38px;
      color: #ffffff;
      font-weight: 500;
      font-family: 'Source Sans Pro', sans-serif;
    }
    .courses-info {
      text-align: center;
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 500;
      font-size: 18px;
      margin: 10px 0;
      ul {
        color: $secondary-dark;
        margin: 10px 0;
        padding: 0;
        li {
          list-style: none;
          margin-bottom: 2px;
        }
      }
    }
    button.manage-subscription {
      margin: 10px auto;
    }
    form {
      font-size: 12px;
      color: $primary;
      .input-wrapper {
        label{
          font-weight: 800;
          margin-bottom: 8px;
        }
        input  {
          border: 1px solid $primary;
          border-radius: 2px;
          height: 45px;
          margin-bottom: 15px;
        }
      }
      .btn {
        width: 100%;
      }
    }
  }
}
