@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.PasswordReset {
  .actions-wrapper {
    text-align: center;
    .btn {
      width: 100%;
      margin-bottom: 15px;
    }
    a {
      color: $secondary-dark;
    }
  }
}