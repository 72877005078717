@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.Upgrade {
  margin: 0 -30px;
  padding: 0 30px 30px;
  border-top: solid 1px $primary;
  border-bottom: solid 1px $primary;
  .title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 1.4;
    margin: 30px 0 10px;
  }
  .sub-title {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 1.4;
    margin: 0 10% 20px;

    a {
      color: $secondary-dark;
    }
  }
  .product-card {
    color: white;
    background-color: $primary;
    border-radius: 15px;
    overflow: hidden;
    .product-card-header {
      background-color: $primary-dark;
      padding: 15px 0;
      text-align: center;
      .title {
        font-weight: 400;
        text-transform: uppercase;
        font-size: 20px;
        letter-spacing: 0.5px;
        margin: 0;
      }
      .price {
        font-size: 30px;
        font-weight: 900;
        color: $secondary;
      }
    }
    .product-card-footer {
      padding: 20px 20px;
    }
    .btn {
      margin: 15px 0 0;
      padding: 5px;
      font-size: 16px;
      display: inline-block;
    }
  }
}