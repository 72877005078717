@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.ProgressRing {
  z-index: 3;
  circle {
    stroke: white;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;

    &.progress {
      stroke: $secondary;
    }
  }
  text {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    font-size: 26px;
  }
}