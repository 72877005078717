@import "colours";

$mobileBreakpoint: 723px;
body {
  font-family: 'Source Sans Pro', sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
  }
}

// fields default styles
.checkbox-wrapper{
  display: flex;
  align-items: center;
  &.error {
    label:before {
      border-color: red!important;
    }
  }
  label, .checkbox {
    display: inline-block;
    margin: 0;
  }
}

.input-wrapper{
  .info {
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
}

.validation-error {
  color: #cd4e61;
  text-align: right;
  &::first-letter{
    text-transform: uppercase;
  }
}
// END fields default styles

.screen-header{
  color: $primary;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 40px;
  font-style: italic;
}

@media (min-width: $mobileBreakpoint) {
  .tile-wrapper {
    padding-left: 8%;
    padding-right: 8%;
    h1 {
      margin-bottom: 30px;
    }
  }
}

.Tile {
  width: 100%;
  height: 143px;
  opacity: 0.95;
  border-radius: 14px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  .gradient{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .85;
    z-index: 1;
  }
  .text {
    h2, h3 {
      position: relative;
      display: block;
      z-index: 2;
      margin: 0;
    }
  }
}

.btn, .ui.btn {
  border: none;
  border-radius: 4px;
  padding: 13px;
  display: flex;
  text-transform: uppercase;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  svg {
    margin-left: 5px;
    font-size: 15px;
  }
  &.btn-primary-gradient {
    color: #fff;
    background: $primary; /* Old browsers */
    background: -moz-linear-gradient(top, $primary 0%, $primary 50%, $primary-dark 51%, $primary-dark 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, $primary 0%,$primary 50%,$primary-dark 51%,$primary-dark 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, $primary 0%, $primary 50%,$primary-dark 51%,$primary-dark 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6d2896', endColorstr='#56125b',GradientType=0 ); /* IE6-9 */

  }
  &.btn-primary {
    color: #fff;
    background-color: $primary;
  }
  &.btn-primary-dark {
    color: #fff;
    background-color: $primary-dark;
  }
  &.btn-secondary-dark {
    color: #fff;
    background-color: $secondary-dark;
  }
  &.btn-secondary {
    color: #fff;
    background-color: $secondary;
  }
}

.form {
  h3 {
    font-size: 18px;
    margin-bottom: 0;
    font-family: inherit;
    font-weight: 500;
  }
  .input-wrapper {
    margin-top: 5px;
    label {
      font-size: 12px;
      font-weight: 500;
    }
    input {
      width: 100%;
      border: 1px solid $primary;
      border-radius: 2px;
      background-color: #FFFFFF;
      height: 45px;
      padding-left: 10px;
      &:disabled {
        background-color: #D8D8D8;
      }
    }
    .ui.input.focus>input, .ui.input>input:focus {
      border-color: black;
      border-width: 2px;
    }
  }
  .checkbox-wrapper {
    margin-top: 20px;

    label {
      padding-left: 8px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.2;
    }
    .ui.checkbox {
      height: 25px;
      width: 25px;

      label:focus::before,
      label:active::before,
      label:hover::before,
      label:before,
      input:focus~label:before,
      input:checked~label:before {
        border-color: $primary;
        border-radius: 5px;
      }
      label:focus:after,
      label:active:after,
      label:hover:after,
      label:after,
      input:focus~label:after,
      input:checked~label:after {
        color: $primary;
      }

      label:before, label:after {
        height: 25px;
        width: 25px;
        font-size: 22px;
      }
      label:after{
        top: -2px;
      }
    }
  }
}

#MathJax_Message {
  display: none!important;
}

.calculator-image {
  z-index: 3;
  position: absolute;
  width: 19px;
  top: 10px;
  right: 10px;
  &.disallow {
    width: 22px;
  }
}

.disabled-link {
  cursor: default;
  pointer-events: none;
}

.disabled {
  .gradient {
    background: linear-gradient(270deg, #8e8e8e 0%, #565656 100%)!important;
  }
}