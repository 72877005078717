@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.group-wrapper {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  .slick-slider {
    transition: .5s ease;

    .slick-list{
      overflow: visible;
      width: 80%;
      .slick-slide{
        .tile-wrap{
          margin-right: 5px;
        }
        &:last-child .tile-wrap {
         margin-right: 0;
        }
      }
    }
  }
}