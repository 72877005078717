@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.Header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    height: 50px;
  }
  .actions{
    svg{
      height: 24px;
      width: 24px;
      margin-left: 9px;
      &.user{
        color: $primary;
      }
      &.logout{
        color: $secondary-dark;
      }
    }
  }
}

@media (min-width: $mobileBreakpoint) {
  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}