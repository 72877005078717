@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.Tile{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;

  .graduation-cap {
    position: absolute;
    left: 10px;
    top: 14px;
    z-index: 3;
    height: 18px;
    width: 18px;
  }

  .text {
    text-align: center;
    h2, h3 {
      font-family: 'Source Sans Pro', sans-serif;
    }
    h2 {
      font-weight: 500;
      font-size: 24px;
    }
    h3 {
      font-weight: 100;
      font-size: 20px;
    }
  }
}