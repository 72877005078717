@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.Questions {
  margin-top: 50px;

  .questions-wrap {
    padding: 0 5px;
  }
}

@media (min-width: $mobileBreakpoint) {
  .Questions {
    .questions-wrap {
      padding: 0 25%;
    }
  }
}