@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.Breadcrumbs{
  display: flex;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
  color: $primary;
  svg {
    margin: 0 5px;
    color: $primary;
  }
  span{
    font-weight: 500;
    &.bold {
      font-weight: 900;
    }
  }
}