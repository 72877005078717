@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.TrailBanner{
  background-color: $primary;
  padding: 10px 14px;
  margin: 0 -14px;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  margin-bottom: 10px;
  .content-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  }
  .btn {
    text-transform: capitalize;
    border: 0.5px solid #FFFFFF;
    border-radius: 5px;
    padding: 5px 15px;
    font-weight: 900;
  }
}

.home .TrailBanner{
  margin-bottom: -25px;
}