@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.ProgressedTile{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  svg {
    flex-shrink: 0;
  }
  .text {
    text-align: left;
    color: black;
    h2, h3 {
      font-family: 'Source Sans Pro', sans-serif;
    }
    h2 {
      font-weight: 500;
      font-size: 22px;
      padding-bottom: 5px;
    }
    h3 {
      font-weight: 100;
      font-size: 18px;
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .ProgressedTile{
    justify-content: center;

    .text {
      display: none;
    }
  }
}
