@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.ui.modals {
  .ui.modal{
    .content {
      text-align: center;
      background: $primary;
      border-radius: 0;
      color: white;
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 900;
      h3 {
        font-size: 30px;
        margin-top: -15px;
        margin-bottom: 15px;
      }
      p{
        font-size: 18px;
        line-height: 1.4;
      }
      img {
        width: 150px;
        margin-bottom: 20px;
      }
      .btn {
        margin: 30px auto 45px;
        min-width: 30%;
      }
    }
  }
}