@import "../../../../styles/colours";

$mobileBreakpoint: 723px;
.Upgrade.Congrats {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
  text-align: center;
  padding: 0 35px;
  i {
    color: $primary;
  }
  h1 {
    color: $primary;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 1.2px;
    font-family: 'Source Sans Pro', sans-serif;
    margin-top: 15px;
  }
  p {
    font-size: 18px;
  }
  .btn {
    margin-top: 15px;
    width: 75%;
    font-weight: 900;
  }
}