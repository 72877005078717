@import "../../../styles/colours";

$mobileBreakpoint: 723px;
@media (min-width: $mobileBreakpoint) {
  .Lessons {
    text-align: center;
    h1 {
      margin: 40px 0;
    }
  }
}