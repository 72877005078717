@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.PaymentForm {
  .payment-symbols {
      height: 30px;
      margin-top: 10px;

    img {
      height: 100%;
    }
  }
}