@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.PasswordEmail {
  p {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 300;
  }
  .form {
    .actions-wrapper {
      display: flex;
      align-items: center;
      .btn {
        padding: 13px 25px;
        margin-right: 15px;
      }
      a {
        color: $secondary-dark;
      }
    }
  }
}