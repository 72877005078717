@import "../../../../styles/colours";

$mobileBreakpoint: 723px;
.Upgrade.Form {
  background-color: white;
  
  .form {
    background-color: rgba(208,232,218,.4);
    padding: 15px;
    margin-top: 30px;

    .PaymentForm {
      h3 {
        font-weight: 800;
      }
      .input-wrapper {
        margin-top: 15px;
      }
      .row {
        display: flex;
        justify-content: space-between;
        .input-wrapper {
          flex: 0 0 48%;
        }
      }
    }

    button {
      width: 90%;
      margin: 20px auto;
    }
  }
}