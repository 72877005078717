@import "../../../styles/colours";

$mobileBreakpoint: 723px;
.QuestionItem{
  text-align: center;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: normal;
  padding: 21px 0;
  border-top: 1px solid $primary;

  &.demo {
    background-image: url("../../../static/img/demo_answer_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    padding: 21px 15px 30px;
    border-radius: 20px;

    .answer-wrap {
      background-color: white;
    }

    &.revealed {
      background-image: none;

      .content {
        color: $primary;
      }

      .answer-wrap {
        color: #ffffff;
        background-image: url("../../../static/img/demo_answer_bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    h2 {
      color: $secondary-dark;
      font-style: italic;
      font-size: 30px;

      @media (min-width: $mobileBreakpoint) {
        font-size: 50px;
      }
    }
    .content {
      color: #ffffff;
      font-size: 16px;

      @media (min-width: $mobileBreakpoint) {
        font-size: 20px;
      }
    }
  }

  .btn.reveal-answer {
    padding: 4px 30px 4px 15px;
    text-align: left;
    color: $primary;
    line-height: 0.9;
    display: flex;
    align-items: flex-end;
    position: relative;
    span {
      width: 40px;
      z-index: 9;
    }
    svg {
      fill: $primary;
      position: absolute;
      width: 52px;
      height: 52px;
      right: -8px;
      bottom: -11px;
      z-index: 10;
    }
  }

  &:first-child{
    border: none;
  }
  h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    color: $primary;
    font-size: 18px;
  }
  .content{
    margin-bottom: 23px;
    img {
      margin-top: 10px;
      max-width: 100%;
    }
  }
  .answer-wrap{
    border: 1px solid $primary;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    .answer {
      padding: 30px 20px;
      text-align: center;
      img {
        margin-top: 10px;
        max-width: 100%;
      }
    }
    .overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      z-index: 3;
      button {
        z-index: 2;
      }
    }
  }
  .explanation-wrap {
    border-radius: 8px;
    overflow: hidden;
    margin-top: 7px;
    margin-bottom: 3px;
    position: relative;
    iframe {
      width: 100%;
      height: auto;

      @media (min-width: $mobileBreakpoint) {
        height: 318px;
      }
    }
  }
  .buttons-container {
    display: flex;
    .btn {
      padding: 6px 12px;
      width: 50%;
      justify-content: center;
      line-height: 1.3;
      &:first-child{
        margin-right: 5px;
      }
    }
  }

  .response {
    display: flex;
    .btn {
      flex-grow: 1;
      padding: 13px;
      justify-content: center;
      margin-right: 5px;
    }
    .ui.checkbox {
      height: 40px;
      width: 40px;
      &.checked.fitted {
        input:checked:focus~label:before,
        input:checked:focus~label:after {
            color: $primary;
            border-color: $primary;
          }
        }
      label:before, label:after {
        height: 40px;
        width: 40px;
        font-size: 35px;
        color: $primary;
        border-color: $primary;
      }
      label:after{
        top: 10px;
      }
    }
  }
}